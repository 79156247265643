import { ContainerImgPautas, ImgPauta } from "../../StyledComponents/Pautas";

function Pauta({ img, link = false }) {
  return (
    <>
      {img !== "" && (
        <ContainerImgPautas>
          {
            link && <a href="https://santafeciudad.gov.ar/residuos/" target="_blank"><ImgPauta src={img} alt="imagen de pauta" /></a>
          }
          {
            !link && <ImgPauta src={img} alt="imagen de pauta" />
          }
        </ContainerImgPautas>
      )}
    </>
  );
}

export { Pauta };
